import Header from '../components/header';
import Footer from '../components/Footer';

export default function Contact(){

return(
    <div>
        <Header/>
        <div className='productspage-main'>
            <div className='contactpage'>
            <h1>CONTACT US</h1><br/>
            <h2>Call us on the phone: <span>+91 810 601 9891</span></h2>
            <h2>Write Us at: <span>care@hea18.com</span></h2>
            <h2>Address: <br/>
            <span>8-3-167/k/49, #203,<br/>Second Floor<br/> Raaga Residency <br/>Kalyan Nagar Phase III<br/>Mothi Nagar
            <br/>Hyderabad, Telangana-500018 <br/>INDIA</span></h2>
            </div>
           
        </div>
        <Footer/>
    </div>
);
}