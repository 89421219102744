import logo from '../assets/logo/logo.png';

function header() {
    return (
        
    <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <ul className='primarymenu'>
            <li><a href="/">Home</a></li>
            <li><a href="/Products">Products</a></li>
            <li><a href="/Products">Blog</a></li>
            <li><a href="/About">About Us</a></li>
            <li><a href="/Contact">Contact Us</a></li>
        </ul>
    </header>
    );
    
  }
export default header;
