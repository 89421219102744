import Header from '../components/header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

import acne from '../assets/products/acnefw.jpeg';
import serum from '../assets/products/hairserum.jpeg';
import replenish from '../assets/products/Replenish.jpeg';
import revital from '../assets/products/revitalize.jpeg';

export default function Contact(){

return(
    <div>
        <Header/>
        <Banner/>
          
        <div className='productspage-main'>
            <div className='productpagecard'>
                <div className='productpagecard-inner'>
                    <img src={acne} className="productpagecard-image" alt="acne" />
                    <h2 className='productpagecard-title'>ALLEVIATING ACNE CLEAR FACE WASH</h2>
                     <div className='productpagecardbtn'>
                        <a href="/Products/Alleviating-acne-clear-face-wash" className='productpagecard-button'> Discover</a>
                    </div>
                   
                </div>
            </div>
            <div className='productpagecard'>
                <div className='productpagecard-inner'>
                    <img src={serum} className="productpagecard-image" alt="serum" />
                    <h2 className='productpagecard-title'>ULTRA NOURISHING HAIR SERUM</h2>
                     <div className='productpagecardbtn'>
                    <a href="/Products/Ultra-nourishing-hair-serum" className='productpagecard-button'> Discover</a>
                    </div>
                </div>
            </div>
            </div>
            <div className='productspage-main'>
            <div className='productpagecard'>
                <div className='productpagecard-inner'>
                    <img src={replenish} className="productpagecard-image" alt="replenish" />
                    <h2 className='productpagecard-title'>RE-PLENISH HAIR CARE SHAMPOO</h2>
                     <div className='productpagecardbtn'>
                    <a href="/Products/Re-plenish-hair-care-shampoo" type="button" className='productpagecard-button'> Discover</a>
                    </div>
                </div>
            </div>
            <div className='productpagecard'>
                <div className='productpagecard-inner'>
                    <img src={revital} className="productpagecard-image" alt="reviral" />
                    <h2 className='productpagecard-title'>RE-VITALIZE HAIR CARE SHAMPOO</h2>
                     <div className='productpagecardbtn'>
                    <a href="/Products/Re-vitalize-hair-care-shampoo" className='productpagecard-button'> Discover</a>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
);
}