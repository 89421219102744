import logo from '../assets/logo/logo.png';
export default function Footer(){
    return(
        <>
        <div className="footer-menu">
            <div className='footer-menu-div'>
                <div className='footer-menu-logo'>
                    <img src={logo} className="footer-logo" alt="logo" />
                </div>
                
            </div>
            <div className='footer-menu-div'>
                 <div className='footer-nav'>
                    <ul className='footermenu'>
                        <li><a href="/">Home</a></li>
                        <li><a href="/Products">Products</a></li>
                        <li><a href="/Products">Blog</a></li>
                        <li><a href="/About">About Us</a></li>
                        <li><a href="/Contact">Contact Us</a></li>
                    </ul>
                    <ul className='socialmenu'>
                        <li><a href="/">Twitter</a></li>
                        <li><a href="/Products">Facebook</a></li>
                        <li><a href="/Products">Instagram</a></li>
                         
                    </ul>
                 </div>
            </div>
            <div className='footer-menu-div'>
                <div className='footer-nav'>
                    <p className='footeraddress'>
                        #49, 203, Raaga Residency<br/>
                        Kalyan Nagar Venture III<br/>
                        Mothi Nagar<br/>
                        Hyderabad<br/>
                        Telangana <br/>
                        INDIA<br/>
                        500018
                    </p> 
                 </div>
            </div>
        
        </div>
        <div className='footer-cpyrt'>
            Copyright 2023 by HEA18 COSMECEUTICALS. All Rights Reserved.
        </div>
        </>
    );
}