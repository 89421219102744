export default function articles(){
    return(
        <>
            <div className='ourproducts-home-title'>
                <h2><span className='underline'>LATEST POSTS</span></h2>
            </div>
            <div className="container">
                <div className="article-card">
                    <div className="article-card-inner">
                        Article1
                    </div>
                </div>
                <div className="article-card">
                    <div className="article-card-inner">
                        Article1
                    </div>
                </div>
                <div className="article-card">
                    <div className="article-card-inner">
                        Article1
                    </div>
                </div>
            </div>
        </>
    )
}