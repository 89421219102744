import Header from '../components/header';
import Footer from '../components/Footer';
import replenish from '../assets/products/Replenish.jpeg';
import RelatedArticles from '../components/RelatedArticlesreplenish';


export default function Replenish(){

return(
    <div>
        <Header/>
        <div className='indproductpagemain'>
            <div className='indproductpagemain-part'>
                <div className='indproductpagemain-image'>
                <img src={replenish} className="productpagecard-image" alt="replenish" />
                </div>
                <div className='indproductpagemain-desc'>
                    <h1><span className='upperline'>Re-Plenish</span> <br/>Hair Care Shampoo</h1>
                    <h5><span className='type'>Type: </span>Liquid  </h5>
                    <h5><span className='use'>Use:</span> Anti-Acne</h5>
                    <h2>Description </h2>
                    <p>Powered with Glycolic acid and Salicylic acid, Alleviationg acne clear face wash purifies pores, expunges dead cells and minimizes acne breakputs. The botanicals help to regulate sebum production, minimize acne marks and provide vibrantly glowing skin</p>
                    <h2>Benefits </h2>
                    <ul className='productdesc-ul'>
                        <li>Detangles hair & frizz free </li>
                        <li>Revives dull & rough strands</li>
                        <li>Voluminous & shiny hair</li>
                    </ul>
                    <h2>Active Ingredients </h2>
                    <ul className='productdesc-ul'>
                        <li>Hydrolyzed Keratin</li>
                        <li>DL-Panthenol</li>
                        <li>Niacinamide</li>
                        <li>Biotin</li>
                        <li>Piroctone Olamine</li>
                        <li>Zinc Pyrithione</li>
                        
                    </ul>
                    <h2>How to Use </h2>
                    <p>Wet your face, apply a small amount of alleviating acne clear face wash on wet face and gently massage with you finguretips in a circular motion. Rinse off with water and pat dry</p>
                    <h2>Caution</h2>
                    <p>For External use only<br/>
                        Avoid contact with eyes. If contact occurs, rinse thoroughly with water<br/>
                        Patch test is advised before using<br/>
                        Discontinue use if irritation occurs<br/>
                        Store at room temperature </p>
                 </div>
            </div>
        </div>
        <div className='indproductpagemainsecond'>

</div>
        <RelatedArticles/>     
        <Footer/>
    </div>
);
}