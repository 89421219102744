import Header from '../components/header';
import Footer from '../components/Footer';
import serum from '../assets/products/hairserum.jpeg';
import RelatedArticles from '../components/RelatedArticlesserum';

export default function Ultra(){

return(
    <div>
    <Header/>
    <div className='indproductpagemain'>
        <div className='indproductpagemain-part'>
            <div className='indproductpagemain-image'>
            <img src={serum} className="productpagecard-image" alt="serum" />
            </div>
            <div className='indproductpagemain-desc'>
                <h1><span className='upperline'>Ultra Nourishing</span> Hair Serum</h1>
                <h5><span className='type'>Type: </span>  </h5>
                <h5><span className='use'>Use:</span> Anti-hairfall | Strong Hair</h5>
                <h2>Description </h2>
                <p>Packed with natural actives, Ultra nourishing hair serum penetrates deeper into the roots that anchors and boosts hair volume. It keeps the scalp moisturized, healthy and also helps to control hair fall. This makes the hair stron, shinier and smooth</p>
                <h2>Benefits </h2>
                <ul className='productdesc-ul'>
                    <li>Detangles hair & frizz free </li>
                    <li>Revives dull & rough strands</li>
                    <li>Voluminous & shiny hair</li>
                </ul>
                <h2>Active Ingredients </h2>
                <ul className='productdesc-ul'>
                    <li>Avacado OIl </li>
                    <li>Argan Oil</li>
                    <li>Rosemary Oil</li>
                    <li>Hibiscus extract</li>
                    <li>Onion Extract</li>
                    <li>Chamomile extract</li>
                    <li>Green Tea</li>
                    
                </ul>
                <h2>How to Use </h2>
                <p>Take the required quantity of the serum into your palm, and apply it all over the scalp. Massage with your finguretips to ease the absorption of the serum. Use everyday for visible results.</p>
                <h2>Caution</h2>
                <p>For External use only<br/>
                    Keep away from eyes and mucous membrane.<br/>
                    Patch test is advised before using<br/>
                    Discontinue use if irritation occurs<br/>
                    Store at room temperature </p>
             </div>
        </div>
    </div>
    <div className='indproductpagemainsecond'>

</div>
    <RelatedArticles/>
    <Footer/>
</div>
);
}