import Header from '../components/header';
import Footer from '../components/Footer';
import RelatedArticles from '../components/RelatedArticlesacne';
import acne from '../assets/products/acnefw.jpeg';

export default function Alleviating(){

return(
    <div>
        <Header/>
        <div className='indproductpagemain'>
            <div className='indproductpagemain-part'>
                <div className='indproductpagemain-image'>
                    <img src={acne} className="productpagecard-image" alt="acne" /> 
                </div>
                <div className='indproductpagemain-desc'>
                    <h1><span className='upperline'>Alleviating</span> <br/>Acne Clear Face Wash</h1>
                    <h5><span className='type'>Type: </span>Liquid  </h5>
                    <h5><span className='use'>Use:</span> Anti-Acne</h5>
                    <h2>Description </h2>
                    <p>Powered with Glycolic acid and Salicylic acid, Alleviationg acne clear face wash purifies pores, expunges dead cells and minimizes acne breakputs. The botanicals help to regulate sebum production, minimize acne marks and provide vibrantly glowing skin</p>
                    <h2>Benefits </h2>

                    <ul className='productdesc-ul'>
                         <li>Sloughs away dead skin cells </li>
                         <li>Decelerates Acne</li>
                         <li>Glow-up skin</li>
                     </ul>
                     <h2>Active Ingredients </h2>
                     <ul className='productdesc-ul'>
                         <li>Glycolic acid </li>
                         <li>Salicylic acid</li>
                         <li>Cinnamomum cassia bark extract </li>
                         <li>Zingiber officinale (Ginger) root extract </li>
                         <li>Poterium officinale (Burnet) root extract</li>
                         <li>Melaleuca alternafolia (Tea tree) oil</li>
                     </ul>
                     
                    <h2>How to Use </h2>
                     
                    <p>Wet your face, apply a small amount of alleviating acne clear face wash on wet face and gently massage with you finguretips in a circular motion. Rinse off with water and pat dry</p>
                    <h2>Caution</h2>
                    <ul className='productdesc-ul'>
                         <li>For External use only</li>
                         <li>Avoid contact with eyes. If contact occurs, rinse thoroughly with water</li>
                         <li>Patch test is advised before using</li>
                         <li>Discontinue use if irritation occurs</li>
                         <li>Store at room temperature</li>
                     </ul>
                     
                 </div>
            </div>
        </div>
       <div className='indproductpagemainsecond'>

       </div>
       <RelatedArticles/>
        <Footer/>
    </div>
);
}