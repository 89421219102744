import Header from '../components/header';
import Footer from '../components/Footer';

export default function About(){

return(
    <div>
        <Header/>
        <div className='productspage-main'>
            <div className='Aboutpage'>
            <h1>ABOUT US</h1>
            <p>Over the years, we have invested our time and effort to formulate best Skincare and Haircare products with combined expertise and passion of dedicated experts. When it comes to formulating our products, we just don’t do a lab run, we pour our hearts to make your skin and hair look beautiful and strong.</p>

            <p>For each HEA product we formulate and develop, our goal remains same to create safe, effective and lovable products which take cares of your every skin and hair problems.</p>

            <p>We have a strong bond with nature, all our products are close to nature. We use Mineralized Water which is Natural, Pure and Active. </p>

            <p>Our selection of ingredients is driven by active molecules used in dermatology with clinical evidences</p>
            <p>When we select active ingredients, we look for molecules recognized in the medical or pharmaceutical realms, with proven positive effects on skin</p>
            <p>We make sensitive skin our problem, not yours.</p>

            </div>
           
        </div>
        <Footer/>
    </div>
);
}