import banner from '../assets/banners/Web-Banner.jpg';

function Banner(){
    return (
        <div className='defaultbanner'>
            <img src={banner} className="App-Banner" alt="Banner" />
        </div>

    );
}

export default Banner;