import acne from '../assets/products/acnefw.jpeg';
import serum from '../assets/products/hairserum.jpeg';
import replenish from '../assets/products/Replenish.jpeg';
import revital from '../assets/products/revitalize.jpeg';


export default function Ourproducts(){
    return(
        <>
        <div className='ourproducts-home-title'>
            <h2><span className='underline'>FEATURED COLLECTION</span></h2>
        </div>
        <div className='ourproducts-home'>
            <div className='card'>
                <div className='card-inner'>
                    <img src={acne} className="card-image" alt="acne" />
                    <h4 className='card-title'>ALLEVIATING ACNE CLEAR FACE WASH</h4>
                    <p className='card-content'> Expunges dead cells and minimizes acne breakouts. Minimizes acne mark and provide vibrant glowing skin
.</p>
                    <div className='cardbtn'>
                        <a href="/Products/Alleviating-acne-clear-face-wash" className='card-button'> Discover</a>
                    </div>
                   
                </div>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <img src={serum} className="card-image" alt="serum" />
                    <h4 className='card-title'>ULTRA NOURISHING HAIR SERUM</h4>
                    <p className='card-content'> Penetrates deeper into the roots that anchors and boost hair volume. Makes hair stronger, shinier and smooth.</p>
                    <div className='cardbtn'>
                    <a href="/Products/Ultra-nourishing-hair-serum" className='card-button'> Discover</a>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <img src={replenish} className="card-image" alt="replenish" />
                    <h4 className='card-title'>RE-PLENISH HAIR CARE SHAMPOO</h4>
                    <p className='card-content'> Cleanses hair effectively without stripping natural oils. Controls scalp oilness, dandruf and locks moisture.</p>
                    <div className='cardbtn'>
                    <a href="/Products/Re-plenish-hair-care-shampoo" type="button" className='card-button'> Discover</a>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <img src={revital} className="card-image" alt="reviral" />
                    <h4 className='card-title'>RE-VITALIZE HAIR CARE SHAMPOO</h4>
                    <p className='card-content'> Enriched with natural actives, Strengthens Hair, Hydrates and adds shine to your Hair. </p>
                    <div className='cardbtn'>
                    <a href="/Products/Re-vitalize-hair-care-shampoo" className='card-button'> Discover</a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}