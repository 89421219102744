import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Contact from './pages/Contact';
import Products from './pages/Products';
import About from './pages/About';
import Aacfw from './pages/Alleviating-acne-clear-face-wash';
import Unhs from './pages/Ultra-nourishing-hair-serum';
import Rphcs from './pages/Re-plenish-hair-care-shampoo';
import Rvhcs from './pages/Re-vitalize-hair-care-shampoo';
 
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/Contact",
    element:<Contact/>,
  },
  {
    path: "/Products",
    element:<Products/>,
  },
  {
    path: "/About",
    element:<About/>,
  },
  {
    path: "/Products/Alleviating-acne-clear-face-wash",
    element:<Aacfw/>,
  },
  {
    path: "/Products/Ultra-nourishing-hair-serum",
    element:<Unhs/>,
  },
  {
    path: "/Products/Re-plenish-hair-care-shampoo",
    element:<Rphcs/>,
  },
  {
    path: "/Products/Re-vitalize-hair-care-shampoo",
    element:<Rvhcs/>,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
