import './App.css';
import ReactDOM from "react-dom/client";
import Header from './components/header';
import Footer from './components/Footer';
import Banner from './components/Banner';
import Products from './components/Ourproducts';
import Articles from './components/articles';
// import AboutBanner from './assets/banners/banner.png';
function App() {
  return (
    <div className="App">
       <Header/>
       <Banner/>
       <Products/>
       <div className='homeabout-content'>
       {/* <img src={AboutBanner} className="about-banner" alt="about-banner" /> */}
       <p>Over the years, we have invested our time and effort to formulate the best skincare and haircare products with the combined expertise and passion of dedicated experts. When it comes to developing our products, we don’t do a lab run, we pour our love into making your skin and hair look beautiful and strong.</p>
       </div>
      <Articles/>
       <Footer/>
    </div>
  );
}

export default App;
